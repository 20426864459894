import Carousel from "bootstrap/js/dist/carousel"

$(()=>{
  const fullScreenModal = document.getElementById('fullScreenModal');
  if (fullScreenModal) {
    fullScreenModal.addEventListener('show.bs.modal', event => {
      // element that triggered the modal
      const anchor = event.relatedTarget;
      // Extract info from data-bs-* attributes
      const imageUrl = anchor.getAttribute('data-bs-image-url');
      const imageUrls = imageUrl.split(' ');
      const imageCaption = anchor.getAttribute('data-bs-image-caption');

      const modalTitle = fullScreenModal.querySelector('#fullScreenModalLabel');

      if(modalTitle){
        modalTitle.textContent = `${imageCaption}`;
      }
      
      const carouselInnerDiv = document.querySelector("#carouselFeatureScreenshots > div.carousel-inner");
      const template = document.querySelector("#carouselImageTemplate");
      for (let i=0; i < imageUrls.length; i++) {
        const clone = template.content.cloneNode(true);
        if (i === 0) {
          clone.querySelector("div").classList.add("active");
        }
        let img = clone.querySelector("img");
        img.src = imageUrls[i];

        // let p = clone.querySelector("p.image-description");
        // p.innerText = `${imageCaption}`;

        carouselInnerDiv.appendChild(clone);
      }
      
      // Select your carousel element
      let screenshotsCarousel = document.querySelector('#carouselFeatureScreenshots');

      // Dispose the existing carousel instance
      let carouselInstance = Carousel.getInstance(screenshotsCarousel);
      if (carouselInstance) {
          carouselInstance.dispose();
      }

      // Re-initialize the carousel
      new Carousel(screenshotsCarousel, {});

    });

    fullScreenModal.addEventListener('hidden.bs.modal', event => {
      const carouselInnerDiv = document.querySelector("#carouselFeatureScreenshots > div.carousel-inner");
      carouselInnerDiv.innerHTML = "";
    });
  }
})